import { UserScope } from '@logto/core-kit';
import { z } from 'zod';
import { Applications, OrganizationScopes, Resources, Scopes, ApplicationSignInExperiences, } from '../db-entries/index.js';
/** The guard for {@link FeaturedApplication}. */
export const featuredApplicationGuard = Applications.guard.pick({
    id: true,
    name: true,
    type: true,
});
export const applicationCreateGuard = Applications.createGuard
    .omit({
    id: true,
    createdAt: true,
    secret: true,
    tenantId: true,
})
    .partial()
    .merge(Applications.createGuard.pick({ name: true, type: true }));
export const applicationPatchGuard = applicationCreateGuard.partial().omit({
    type: true,
    isThirdParty: true,
});
export const applicationUserConsentScopesResponseGuard = z.object({
    organizationScopes: z.array(OrganizationScopes.guard.pick({ id: true, name: true, description: true })),
    resourceScopes: z.array(z.object({
        resource: Resources.guard.pick({ id: true, name: true, indicator: true }),
        scopes: z.array(Scopes.guard.pick({ id: true, name: true, description: true })),
    })),
    userScopes: z.array(z.nativeEnum(UserScope)),
});
export var ApplicationUserConsentScopeType;
(function (ApplicationUserConsentScopeType) {
    ApplicationUserConsentScopeType["OrganizationScopes"] = "organization-scopes";
    ApplicationUserConsentScopeType["ResourceScopes"] = "resource-scopes";
    ApplicationUserConsentScopeType["UserScopes"] = "user-scopes";
})(ApplicationUserConsentScopeType || (ApplicationUserConsentScopeType = {}));
export const applicationSignInExperienceCreateGuard = ApplicationSignInExperiences.createGuard
    .omit({
    applicationId: true,
    tenantId: true,
    termsOfUseUrl: true,
    privacyPolicyUrl: true,
})
    // Align with the sign-in-experience create guard.
    .merge(z.object({
    termsOfUseUrl: z.string().max(2048).url().optional().nullable().or(z.literal('')),
    privacyPolicyUrl: z.string().max(2048).url().optional().nullable().or(z.literal('')),
}));
