import { singleSignOnDomainBlackList } from '../types/sso-connector.js';
/**
 * Find duplicated domains and blocked domains using the domain blacklist.
 *
 * @param domains Array of email domains.
 * @returns
 */
export const findDuplicatedOrBlockedEmailDomains = (domains) => {
    const blackListSet = new Set(singleSignOnDomainBlackList);
    const validDomainSet = new Set();
    const duplicatedDomains = new Set();
    const forbiddenDomains = new Set();
    for (const domain of domains ?? []) {
        if (blackListSet.has(domain)) {
            forbiddenDomains.add(domain);
        }
        if (validDomainSet.has(domain)) {
            duplicatedDomains.add(domain);
        }
        else {
            validDomainSet.add(domain);
        }
    }
    return {
        duplicatedDomains,
        forbiddenDomains,
    };
};
