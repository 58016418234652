import { emailRegEx, phoneRegEx, usernameRegEx } from '@logto/core-kit';
import { z } from 'zod';
import { MfaFactor, jsonObjectGuard, webAuthnTransportGuard } from '../foundations/index.js';
import { emailVerificationCodePayloadGuard, phoneVerificationCodePayloadGuard, } from './verification-code.js';
/**
 * Detailed interaction identifier payload guard
 */
export const usernamePasswordPayloadGuard = z.object({
    username: z.string().min(1),
    password: z.string().min(1),
});
export const emailPasswordPayloadGuard = z.object({
    email: z.string().min(1),
    password: z.string().min(1),
});
export const phonePasswordPayloadGuard = z.object({
    phone: z.string().min(1),
    password: z.string().min(1),
});
export const socialConnectorPayloadGuard = z.object({
    connectorId: z.string(),
    connectorData: jsonObjectGuard,
});
export const socialEmailPayloadGuard = z.object({
    connectorId: z.string(),
    email: z.string(),
});
export const socialPhonePayloadGuard = z.object({
    connectorId: z.string(),
    phone: z.string(),
});
// Interaction flow event types
export var InteractionEvent;
(function (InteractionEvent) {
    InteractionEvent["SignIn"] = "SignIn";
    InteractionEvent["Register"] = "Register";
    InteractionEvent["ForgotPassword"] = "ForgotPassword";
})(InteractionEvent || (InteractionEvent = {}));
export const eventGuard = z.nativeEnum(InteractionEvent);
export const identifierPayloadGuard = z.union([
    usernamePasswordPayloadGuard,
    emailPasswordPayloadGuard,
    phonePasswordPayloadGuard,
    emailVerificationCodePayloadGuard,
    phoneVerificationCodePayloadGuard,
    socialConnectorPayloadGuard,
    socialEmailPayloadGuard,
    socialPhonePayloadGuard,
]);
export const profileGuard = z.object({
    username: z.string().regex(usernameRegEx).optional(),
    email: z.string().regex(emailRegEx).optional(),
    phone: z.string().regex(phoneRegEx).optional(),
    connectorId: z.string().optional(),
    password: z.string().optional(),
});
export var MissingProfile;
(function (MissingProfile) {
    MissingProfile["username"] = "username";
    MissingProfile["email"] = "email";
    MissingProfile["phone"] = "phone";
    MissingProfile["password"] = "password";
    MissingProfile["emailOrPhone"] = "emailOrPhone";
})(MissingProfile || (MissingProfile = {}));
export const bindTotpPayloadGuard = z.object({
    // Unlike identifier payload which has indicator like "email",
    // mfa payload must have an additional type field to indicate type
    type: z.literal(MfaFactor.TOTP),
    code: z.string(),
});
export const bindWebAuthnPayloadGuard = z.object({
    type: z.literal(MfaFactor.WebAuthn),
    id: z.string(),
    rawId: z.string(),
    /**
     * The response from WebAuthn API
     *
     * @see {@link https://developer.mozilla.org/en-US/docs/Web/API/PublicKeyCredential}
     */
    response: z.object({
        clientDataJSON: z.string(),
        attestationObject: z.string(),
        authenticatorData: z.string().optional(),
        transports: webAuthnTransportGuard.array().optional(),
        publicKeyAlgorithm: z.number().optional(),
        publicKey: z.string().optional(),
    }),
    authenticatorAttachment: z.enum(['cross-platform', 'platform']).optional(),
    clientExtensionResults: z.object({
        appid: z.boolean().optional(),
        crepProps: z
            .object({
            rk: z.boolean().optional(),
        })
            .optional(),
        hmacCreateSecret: z.boolean().optional(),
    }),
});
export const bindBackupCodePayloadGuard = z.object({
    type: z.literal(MfaFactor.BackupCode),
});
export const bindMfaPayloadGuard = z.discriminatedUnion('type', [
    bindTotpPayloadGuard,
    bindWebAuthnPayloadGuard,
    bindBackupCodePayloadGuard,
]);
export const totpVerificationPayloadGuard = bindTotpPayloadGuard;
export const webAuthnVerificationPayloadGuard = bindWebAuthnPayloadGuard
    .omit({ response: true })
    .extend({
    response: z.object({
        clientDataJSON: z.string(),
        authenticatorData: z.string(),
        signature: z.string(),
        userHandle: z.string().optional(),
    }),
});
export const backupCodeVerificationPayloadGuard = z.object({
    type: z.literal(MfaFactor.BackupCode),
    code: z.string(),
});
export const verifyMfaPayloadGuard = z.discriminatedUnion('type', [
    totpVerificationPayloadGuard,
    webAuthnVerificationPayloadGuard,
    backupCodeVerificationPayloadGuard,
]);
export const pendingTotpGuard = z.object({
    type: z.literal(MfaFactor.TOTP),
    secret: z.string(),
});
export const pendingWebAuthnGuard = z.object({
    type: z.literal(MfaFactor.WebAuthn),
    challenge: z.string(),
});
export const pendingBackupCodeGuard = z.object({
    type: z.literal(MfaFactor.BackupCode),
    codes: z.array(z.string()),
});
// Some information like TOTP secret should be generated in the backend
// and stored in the interaction temporarily.
export const pendingMfaGuard = z.discriminatedUnion('type', [
    pendingTotpGuard,
    pendingWebAuthnGuard,
    pendingBackupCodeGuard,
]);
export const bindTotpGuard = pendingTotpGuard;
export const bindWebAuthnGuard = z.object({
    type: z.literal(MfaFactor.WebAuthn),
    credentialId: z.string(),
    publicKey: z.string(),
    transports: webAuthnTransportGuard.array(),
    counter: z.number(),
    agent: z.string(),
});
export const bindBackupCodeGuard = pendingBackupCodeGuard;
// The type for binding new mfa verification to a user, not always equals to the pending type.
export const bindMfaGuard = z.discriminatedUnion('type', [
    bindTotpGuard,
    bindWebAuthnGuard,
    bindBackupCodeGuard,
]);
export const verifyMfaResultGuard = z.object({
    type: z.nativeEnum(MfaFactor),
    id: z.string(),
});
