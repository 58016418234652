import { z } from 'zod';
export var HookEvent;
(function (HookEvent) {
    HookEvent["PostRegister"] = "PostRegister";
    HookEvent["PostSignIn"] = "PostSignIn";
    HookEvent["PostResetPassword"] = "PostResetPassword";
})(HookEvent || (HookEvent = {}));
export const hookEventGuard = z.nativeEnum(HookEvent);
export const hookEventsGuard = hookEventGuard.array();
export const hookConfigGuard = z.object({
    /** We don't need `type` since v1 only has web hook */
    // type: 'web';
    /** Method fixed to `POST` */
    url: z.string(),
    /** Additional headers that attach to the request */
    headers: z.record(z.string()).optional(),
    /**
     * @deprecated
     * Retry times when hook response status >= 500.
     * Now the retry times is fixed to 3.
     * Keep for backward compatibility.
     */
    retries: z.number().gte(0).lte(3).optional(),
});
