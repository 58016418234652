@use '@/scss/underscore' as _;

.mfaFactorButton {
  padding: _.unit(3) _.unit(4) _.unit(3) _.unit(3);
  height: unset;
  gap: _.unit(4);
  border-radius: 12px;
  border-color: var(--color-line-divider);
}

.icon {
  width: 20px;
  height: 20px;
  color: var(--color-type-secondary);
}

.title {
  flex: 1;
  @include _.flex-column;
  align-items: flex-start;
  text-align: left;

  .name {
    font: var(--font-label-1);
  }

  .description {
    font: var(--font-body-2);
    color: var(--color-type-secondary);
  }
}

:global(body.desktop) {
  .title {
    .name {
      font: var(--font-label-2);
    }
  }
}
