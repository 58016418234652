@use '@/scss/underscore' as _;

.error {
  font: var(--font-body-2);
  color: var(--color-danger-default);

  ul {
    padding-inline-start: 1rem;
  }
}
