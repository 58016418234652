@use '@/scss/underscore' as _;

.notification {
  padding: _.unit(3) _.unit(4);
  font: var(--font-body-2);
  color: var(--color-type-primary);
  margin: 0 auto _.unit(2);
  background: var(--color-alert-99);
  @include _.flex_row;
}
