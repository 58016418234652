@use '@/scss/underscore' as _;

.form {
  @include _.flex-column;

  > * {
    width: 100%;
  }

  .inputField,
  .link,
  .terms,
  .formErrors,
  .message {
    margin-bottom: _.unit(4);
  }

  .link {
    margin-top: _.unit(-1);
    align-self: start;
    width: auto;
  }

  .message {
    @include _.text-hint;
  }

  .formErrors {
    margin-left: _.unit(0.5);
    margin-top: _.unit(-3);
  }
}

:global(.desktop) {
  .form {
    .link {
      margin-top: _.unit(-2);
    }
  }
}
