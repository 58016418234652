@use '@/scss/underscore' as _;

.passcode {
  @include _.flex-row;
  justify-content: space-between;

  input {
    width: 44px;
    height: 44px;
    border-radius: _.unit(2);
    border: _.border(var(--color-line-border));
    background: transparent;
    text-align: center;
    font: var(--font-label-1);
    color: var(--color-type-primary);
    caret-color: var(--color-brand-default);

    &:focus {
      border: _.border(var(--color-brand-default));
      outline: none;
    }

    &::placeholder {
      color: var(--color-type-secondary);
    }
  }
}

.passcode + .errorMessage {
  margin-left: _.unit(0.5);
  margin-top: _.unit(1);
}

:global(body.desktop) {
  .passcode {
    input {
      font: var(--font-body-2);
      outline: 3px solid transparent;

      &:focus {
        border: _.border(var(--color-brand-default));
        outline-color: var(--color-overlay-brand-focused);
      }
    }
  }
}
