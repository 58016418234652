@use '@/scss/underscore' as _;

.wrapper {
  @include _.full-page;
  @include _.flex-column(normal, normal);
  @include _.full-width;

  > *:last-child {
    margin-bottom: 0;
  }
}

:global(body.mobile) {
  .header {
    margin-top: _.unit(3);
    padding-bottom: _.unit(7);
  }
}

:global(body.desktop) {
  .wrapper {
    padding: _.unit(6) 0;
  }

  .header {
    margin-bottom: _.unit(6);
  }

  .placeholderTop {
    flex: 3;
  }

  .placeholderBottom {
    flex: 5;
  }
}
