@use '@/scss/underscore' as _;


.signature {
  @include _.flex-row;
  font: var(--font-label-2);
  color: var(--color-neutral-variant-70);
  padding: _.unit(1) _.unit(2);
  text-decoration: none;

  .staticIcon {
    display: block;
  }

  .highlightIcon {
    display: none;
  }

  &:hover,
  &:active {
    .staticIcon {
      display: none;
    }

    .highlightIcon {
      display: block;
    }
  }

  .text {
    margin-right: _.unit(1);
  }
}


:global(body.mobile) {
  .signature {
    color: var(--color-neutral-variant-80);
    font: var(--font-label-3);
  }
}
