const action = {
    sign_in: 'Zaloguj się',
    continue: 'Kontynuuj',
    create_account: 'Utwórz konto',
    create_account_without_linking: 'Utwórz konto bez łączenia',
    create: 'Utwórz',
    enter_passcode: 'Wprowadź kod weryfikacyjny',
    confirm: 'Potwierdź',
    cancel: 'Anuluj',
    save_password: 'Zapisz hasło',
    bind: 'Połącz z {{address}}',
    bind_and_continue: 'Połącz i kontynuuj',
    back: 'Wróć',
    nav_back: 'Wstecz',
    nav_skip: 'Pomiń',
    agree: 'Zgadzam się',
    got_it: 'Zrozumiałem',
    sign_in_with: 'Kontynuuj z {{name}}',
    forgot_password: 'Zapomniałeś hasła?',
    switch_to: 'Przełącz na {{method}}',
    sign_in_via_passcode: 'Zaloguj się za pomocą kodu weryfikacyjnego',
    sign_in_via_password: 'Zaloguj się za pomocą hasła',
    change: 'Zmień {{method}}',
    link_another_email: 'Połącz inne konto email',
    link_another_phone: 'Połącz inne konto telefoniczne',
    link_another_email_or_phone: 'Połącz inne konto email lub telefoniczne',
    show_password: 'Pokaż hasło',
    copy: 'Kopiuj',
    verify_via_passkey: 'Weryfikacja za pomocą klucza dostępu',
    download: 'Pobierz',
    single_sign_on: 'Pojedyncze logowanie',
    /** UNTRANSLATED */
    authorize: 'Authorize',
    /** UNTRANSLATED */
    use_another_account: 'Use another account',
};
export default Object.freeze(action);
