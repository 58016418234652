const action = {
    sign_in: 'Sign in',
    continue: 'Continue',
    create_account: 'Create account',
    create_account_without_linking: 'Create account without linking',
    create: 'Create',
    enter_passcode: 'Enter verification code',
    confirm: 'Confirm',
    cancel: 'Cancel',
    save_password: 'Save password',
    bind: 'Link with {{address}}',
    bind_and_continue: 'Link and continue',
    back: 'Go back',
    nav_back: 'Back',
    nav_skip: 'Skip',
    agree: 'Agree',
    got_it: 'Got it',
    sign_in_with: 'Continue with {{name}}',
    forgot_password: 'Forgot your password?',
    switch_to: 'Switch to {{method}}',
    sign_in_via_passcode: 'Sign in with verification code',
    sign_in_via_password: 'Sign in with password',
    change: 'Change {{method}}',
    link_another_email: 'Link another email',
    link_another_phone: 'Link another phone',
    link_another_email_or_phone: 'Link another email or phone',
    show_password: 'Show password',
    copy: 'Copy',
    verify_via_passkey: 'Verify via passkey',
    download: 'Download',
    single_sign_on: 'Single Sign-On',
    authorize: 'Authorize',
    use_another_account: 'Use another account',
};
export default Object.freeze(action);
