import { hexColorRegEx } from '@logto/core-kit';
import { languageTagGuard } from '@logto/language-kit';
import { z } from 'zod';
export const colorGuard = z.object({
    primaryColor: z.string().regex(hexColorRegEx),
    isDarkModeEnabled: z.boolean(),
    darkPrimaryColor: z.string().regex(hexColorRegEx),
});
export const brandingGuard = z.object({
    logoUrl: z.string().url().optional(),
    darkLogoUrl: z.string().url().optional(),
    favicon: z.string().url().optional(),
});
export const languageInfoGuard = z.object({
    autoDetect: z.boolean(),
    fallbackLanguage: languageTagGuard,
});
export var SignInIdentifier;
(function (SignInIdentifier) {
    SignInIdentifier["Username"] = "username";
    SignInIdentifier["Email"] = "email";
    SignInIdentifier["Phone"] = "phone";
})(SignInIdentifier || (SignInIdentifier = {}));
export const signUpGuard = z.object({
    identifiers: z.nativeEnum(SignInIdentifier).array(),
    password: z.boolean(),
    verify: z.boolean(),
});
export const signInGuard = z.object({
    methods: z
        .object({
        identifier: z.nativeEnum(SignInIdentifier),
        password: z.boolean(),
        verificationCode: z.boolean(),
        isPasswordPrimary: z.boolean(),
    })
        .array(),
});
export const connectorTargetsGuard = z.string().array();
export const customContentGuard = z.record(z.string());
export var MfaFactor;
(function (MfaFactor) {
    MfaFactor["TOTP"] = "Totp";
    MfaFactor["WebAuthn"] = "WebAuthn";
    MfaFactor["BackupCode"] = "BackupCode";
})(MfaFactor || (MfaFactor = {}));
export const mfaFactorsGuard = z.nativeEnum(MfaFactor).array();
export var MfaPolicy;
(function (MfaPolicy) {
    MfaPolicy["UserControlled"] = "UserControlled";
    MfaPolicy["Mandatory"] = "Mandatory";
})(MfaPolicy || (MfaPolicy = {}));
export const mfaGuard = z.object({
    factors: mfaFactorsGuard,
    policy: z.nativeEnum(MfaPolicy),
});
