@use '@/scss/underscore' as _;

.wrapper {
  border: _.border(var(--color-line-divider));
  border-radius: 8px;
  padding: _.unit(4);
  @include _.flex-row;
}

.avatar {
  width: 40px;
  height: 40px;
  border-radius: 6px;
  object-fit: fill;
  object-position: center;
  margin-right: _.unit(3);
}

.name {
  font: var(--font-label-2);
}

.identifier {
  font: var(--font-body-3);
  color: var(--color-type-secondary);
}
