@use '@/scss/underscore' as _;

.organizationItem {
  border-radius: _.unit(2);
  padding: _.unit(2.5) _.unit(2);
  cursor: pointer;

  .icon {
    width: 20px;
    height: 20px;
    color: var(--color-type-secondary);
    margin-right: _.unit(2);
  }

  .organizationName {
    font: var(--font-body-2);
    flex: 1;
  }

  &:hover {
    background-color: var(--color-overlay-neutral-hover);
  }

  &[data-selected='true'] {
    color: var(--color-brand-default);

    .icon {
      color: var(--color-brand-default);
    }
  }
  @include _.flex-row;
}
