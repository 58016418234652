@use '@/scss/underscore' as _;

.title {
  font: var(--font-label-2);
  margin-bottom: _.unit(2);
}

.cardWrapper {
  border: _.border(var(--color-line-divider));
  border-radius: 8px;
  padding: _.unit(2) 0;
}

.scopeGroup {
  padding: 0 _.unit(2);

  .scopeGroupHeader {
    @include _.flex-row;
    border-radius: _.unit(2);
    padding: _.unit(2);
    cursor: pointer;

    &:hover {
      background-color: var(--color-overlay-neutral-hover);
    }
  }

  .check {
    color: var(--color-success-pressed);
    width: 20px;
    height: 20px;
    margin-right: _.unit(2);
  }

  .scopeGroupName {
    font: var(--font-body-2);
    flex: 1;
    margin-right: _.unit(2);
  }

  .toggleButton {
    transition: transform 0.2s ease-in-out;
    position: relative;
    width: 20px;
    height: 20px;
    color: var(--color-type-disable);

    &[data-expanded='true'] {
      transform: rotate(180deg);
    }
  }

  .scopesList {
    padding-inline-start: _.unit(8);
    font: var(--font-body-3);
    color: var(--color-type-secondary);
    margin: 0;
  }

  .scopeItem {
    padding: _.unit(0.5) 0 _.unit(0.5) _.unit(1);
    margin-bottom: _.unit(1.5);
  }
}

.terms {
  padding: _.unit(4)  _.unit(4) _.unit(2) _.unit(4);
  border-top: _.border(var(--color-line-divider));
  margin-top: _.unit(2);
  font: var(--font-body-3);
  color: var(--color-type-secondary);
}
