// THIS IS AN AUTOGENERATED FILE. DO NOT EDIT THIS FILE DIRECTLY.
import { z } from 'zod';
import { brandingGuard } from './../foundations/index.js';
const createGuard = z.object({
    tenantId: z.string().max(21).optional(),
    applicationId: z.string().min(1).max(21),
    branding: brandingGuard.optional(),
    termsOfUseUrl: z.string().max(2048).nullable().optional(),
    privacyPolicyUrl: z.string().max(2048).nullable().optional(),
    displayName: z.string().max(256).nullable().optional(),
});
const guard = z.object({
    tenantId: z.string().max(21),
    applicationId: z.string().min(1).max(21),
    branding: brandingGuard,
    termsOfUseUrl: z.string().max(2048).nullable(),
    privacyPolicyUrl: z.string().max(2048).nullable(),
    displayName: z.string().max(256).nullable(),
});
export const ApplicationSignInExperiences = Object.freeze({
    table: 'application_sign_in_experiences',
    tableSingular: 'application_sign_in_experience',
    fields: {
        tenantId: 'tenant_id',
        applicationId: 'application_id',
        branding: 'branding',
        termsOfUseUrl: 'terms_of_use_url',
        privacyPolicyUrl: 'privacy_policy_url',
        displayName: 'display_name',
    },
    fieldKeys: [
        'tenantId',
        'applicationId',
        'branding',
        'termsOfUseUrl',
        'privacyPolicyUrl',
        'displayName',
    ],
    createGuard,
    guard,
    updateGuard: guard.partial(),
});
